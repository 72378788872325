import { Component } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { heading, metaDescription, metaKeywords, title } from 'constants/meta';
import MainTemplate from 'components/templates/MainTemplate';
import Alert from 'components/atoms/Alert';
import ButtonLink from 'components/atoms/ButtonLink';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

const Title = styled.h1`
  font-size: 2em;
  font-weight: normal;
`;

const Wrapper = styled.div`
  max-width: 40em;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 1em;
`;

export default class PasswordResetPage extends Component {
  state = {
    error: null,
    success: false,
    step: this.props.match.params.id ? 2 : 1,
  };

  clearError = () => {
    this.setState({
      error: null,
    });
  };

  handleError = (error) => {
    let errorMessage = 'Произошла ошибка. Попробуйте позднее.';

    if (
      error.graphQLErrors &&
      error.graphQLErrors[0] &&
      error.graphQLErrors[0].extensions &&
      error.graphQLErrors[0].extensions.code &&
      error.graphQLErrors[0].extensions.code === 'BAD_USER_INPUT'
    ) {
      errorMessage = error.graphQLErrors[0].message;
    }
    this.setState({
      error: errorMessage,
    });
  };

  handleSuccess = () => {
    this.setState({
      success: true,
    });
  };

  render() {
    return (
      <MainTemplate>
        <Helmet>
          <title>{title.passwordReset}</title>
          <meta name="keywords" content={metaKeywords.passwordReset} />
          <meta name="description" content={metaDescription.passwordReset} />
        </Helmet>
        <Title>{heading.passwordReset}</Title>
        <Wrapper>
          {this.state.error && <StyledAlert type="error">{this.state.error}</StyledAlert>}
          {this.state.step === 1 && (
            <>
              {this.state.success && (
                <StyledAlert type="success">
                  Если указанный E-mail использовался при регистрации, то на него было выслано
                  письмо с ссылкой для сброса пароля. Ссылка будет действительна в течение 24 часов,
                  после чего воспользоваться ей будет невозможно. Если по истечению нескольких минут
                  письмо не пришло, проверьте папку &quot;Спам&quot;.
                </StyledAlert>
              )}
              {!this.state.success && (
                <StepOne
                  onError={this.handleError}
                  onSuccess={this.handleSuccess}
                  clearError={this.clearError}
                />
              )}
            </>
          )}
          {this.state.step === 2 && (
            <>
              {this.state.success && (
                <>
                  <StyledAlert type="success">Пароль успешно изменён.</StyledAlert>
                  <ButtonLink inline neutral to="/signin">
                    Войти с новым паролем
                  </ButtonLink>
                </>
              )}
              {!this.state.success && (
                <StepTwo
                  onError={this.handleError}
                  onSuccess={this.handleSuccess}
                  clearError={this.clearError}
                  token={this.props.match.params.id}
                />
              )}
            </>
          )}
        </Wrapper>
      </MainTemplate>
    );
  }
}
