import { Component } from 'react';
import { Mutation } from 'react-apollo';
import initializePasswordResetMutation from 'graphql/mutations/initializePasswordReset.graphql';
import Form from 'components/atoms/Form';
import Button from 'components/atoms/Button';

export default class StepOne extends Component {
  state = {
    email: '',
    initialized: false,
  };

  componentDidMount() {
    this.setState({
      initialized: true,
    });
  }

  handleChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  render() {
    return (
      <Mutation
        mutation={initializePasswordResetMutation}
        variables={{ email: this.state.email }}
        onError={this.props.onError}
        onCompleted={this.props.onSuccess}
      >
        {(initializePasswordReset, { loading }) => (
          <Form
            method="post"
            onSubmit={(event) => {
              event.preventDefault();
              this.props.clearError();
              initializePasswordReset();
            }}
          >
            <Form.Control>
              <Form.Label htmlFor="email">E-mail</Form.Label>
              <Form.Input
                type="email"
                id="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                disabled={loading || !this.state.initialized}
                required
              />
            </Form.Control>
            <Button type="submit" primary disabled={loading || !this.state.initialized}>
              {loading || !this.state.initialized ? 'Загрузка...' : 'Восстановить'}
            </Button>
          </Form>
        )}
      </Mutation>
    );
  }
}
