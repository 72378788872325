import { Component } from 'react';
import { Mutation } from 'react-apollo';
import passwordResetMutation from 'graphql/mutations/passwordReset.graphql';
import Form from 'components/atoms/Form';
import Button from 'components/atoms/Button';

export default class StepOne extends Component {
  state = {
    password: '',
    passwordRepeat: '',
    initialized: false,
  };

  componentDidMount() {
    this.setState({
      initialized: true,
    });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    return (
      <Mutation
        mutation={passwordResetMutation}
        variables={{
          password: this.state.password,
          passwordRepeat: this.state.passwordRepeat,
          token: this.props.token,
        }}
        onError={this.props.onError}
        onCompleted={this.props.onSuccess}
      >
        {(passwordReset, { loading }) => (
          <Form
            method="post"
            onSubmit={(event) => {
              event.preventDefault();
              this.props.clearError();
              passwordReset();
            }}
          >
            <Form.Control>
              <Form.Label htmlFor="password">Новый пароль</Form.Label>
              <Form.Input
                type="password"
                id="password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
                disabled={loading || !this.state.initialized}
                required
              />
            </Form.Control>
            <Form.Control>
              <Form.Label htmlFor="passwordRepeat">Повтор нового пароля</Form.Label>
              <Form.Input
                type="password"
                id="passwordRepeat"
                name="passwordRepeat"
                value={this.state.passwordRepeat}
                onChange={this.handleChange}
                disabled={loading || !this.state.initialized}
                required
              />
            </Form.Control>
            <Button type="submit" primary disabled={loading || !this.state.initialized}>
              {loading || !this.state.initialized ? 'Загрузка...' : 'Сменить пароль'}
            </Button>
          </Form>
        )}
      </Mutation>
    );
  }
}
